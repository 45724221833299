import React from 'react'
import { Helmet } from "react-helmet-async"


function LandingMetas({lang, keepTitle=false}) {
  return (
    <Helmet>
            <meta property="description" content={
              lang==='en'?
              `IEEE NTUA Student Branch is a volunteer organization at the National Technical University of Athens, led by students. The branch is made up of five society chapters: Computer Society (CS), Robotics and Automation Society (RAS), Power and Energy Society (PES), Engineering in Medicine and Biology Society (EMBS), and Communications Society (ComSoc). We collaborate, create projects, organize workshops and talks, and participate in various competitions. IEEE NTUA aims to inspire other students and create an active community based on creativity and innovation.`
              :
              `Το IEEE NTUA Student Branch είναι μια φοιτητική εθελοντική οργάνωση στο Εθνικό Μετσόβιο Πολυτεχνείο. Αποτελείται από πέντε Society Chapters: Computer Society (CS), Robotics and Automation Society (RAS), Power and Energy Society (PES), Engineering in Medicine and Biology Society (EMBS) και Communications Society (ComSoc). Σε αυτό, συνεργαζόμαστε και δημιουργούμε projects, διοργανώνουμε workshops, ομιλίες και συμμετέχουμε σε διάφορους διαγωνισμούς. Στόχος μας είναι να εμπνεύσουμε άλλους φοιτητές και να δημιουργήσουμε μια ενεργή κοινότητα βασισμένη στη δημιουργικότητα και την καινοτομία.`} data-rh="true"/>
            
            <meta property="og:type" content="website" />
            
            { keepTitle && <meta property="og:title" content="IEEE NTUA Student Branch" data-rh="true"/>}
            
            <meta property="og:description" data-rh="true" content={
              lang==='en'?
              `IEEE NTUA Student Branch is a volunteer organization at the National Technical University of Athens, led by students. The branch is made up of five society chapters: Computer Society (CS), Robotics and Automation Society (RAS), Power and Energy Society (PES), Engineering in Medicine and Biology Society (EMBS), and Communications Society (ComSoc). We collaborate, create projects, organize workshops and talks, and participate in various competitions. IEEE NTUA aims to inspire other students and create an active community based on creativity and innovation.`
              :
              `Το IEEE NTUA Student Branch είναι μια φοιτητική εθελοντική οργάνωση στο Εθνικό Μετσόβιο Πολυτεχνείο. Αποτελείται από πέντε Society Chapters: Computer Society (CS), Robotics and Automation Society (RAS), Power and Energy Society (PES), Engineering in Medicine and Biology Society (EMBS) και Communications Society (ComSoc). Σε αυτό, συνεργαζόμαστε και δημιουργούμε projects, διοργανώνουμε workshops, ομιλίες και συμμετέχουμε σε διάφορους διαγωνισμούς. Στόχος μας είναι να εμπνεύσουμε άλλους φοιτητές και να δημιουργήσουμε μια ενεργή κοινότητα βασισμένη στη δημιουργικότητα και την καινοτομία.`} />
            
            <meta property="og:image" content={window.location.origin + "/ieee-landing.png"} data-rh="true"/>
            <meta property="og:site_name" content="IEEE NTUA Student Branch" data-rh="true"/>
            <meta property="og:image:alt" content="IEEE NTUA Student Branch"data-rh="true"/>
            <meta name="twitter:card" content="summary_large_image" data-rh="true"/>
            <meta name="twitter:site" content="IEEE NTUA Student Branch" data-rh="true"/>

            { keepTitle && <meta name="twitter:title" content="IEEE NTUA Student Branch" data-rh="true"/>}

            <meta name="twitter:description" data-rh="true"  content={
              lang==='en'?
              `IEEE NTUA Student Branch is a volunteer organization at the National Technical University of Athens, led by students. The branch is made up of five society chapters: Computer Society (CS), Robotics and Automation Society (RAS), Power and Energy Society (PES), Engineering in Medicine and Biology Society (EMBS), and Communications Society (ComSoc). We collaborate, create projects, organize workshops and talks, and participate in various competitions. IEEE NTUA aims to inspire other students and create an active community based on creativity and innovation.`
              :
              `Το IEEE NTUA Student Branch είναι μια φοιτητική εθελοντική οργάνωση στο Εθνικό Μετσόβιο Πολυτεχνείο. Αποτελείται από πέντε Society Chapters: Computer Society (CS), Robotics and Automation Society (RAS), Power and Energy Society (PES), Engineering in Medicine and Biology Society (EMBS) και Communications Society (ComSoc). Σε αυτό, συνεργαζόμαστε και δημιουργούμε projects, διοργανώνουμε workshops, ομιλίες και συμμετέχουμε σε διάφορους διαγωνισμούς. Στόχος μας είναι να εμπνεύσουμε άλλους φοιτητές και να δημιουργήσουμε μια ενεργή κοινότητα βασισμένη στη δημιουργικότητα και την καινοτομία.`} />

            <meta name="twitter:image" content={window.location.origin + "/ieee-landing.png"} data-rh="true"/>

            {keepTitle && <title>IEEE NTUA Student Branch</title>}
            
    </Helmet>
  )
}

export default LandingMetas