import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Landing from './pages/Landing';
import Contact from './pages/Contact';
import Events from './pages/Events';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './pages/About';
import News from './pages/News';
import Event from './pages/Event';
import Preloader from './pages/Preloader';
import Chapter from './pages/Chapter';
import JoinIEEE from './pages/JoinIEEE';
import Awards from './pages/Awards';
import NewsArticle from './pages/NewsArticle';
import NotFoundPage from './pages/NotFoundPage';


function App() { 
  return (
    <div className="App">
     <Router trailingSlash={true}>
        <Routes>

            <Route path='/'   exact element={<Navigate  to='/gr/'/>} />
            <Route path='/en' exact={false} element={<Navigate replace={true} to='/en/'/>} />
            <Route path='/gr' exact={false} element={<Navigate  replace={true} to='/gr/'/>} />

            <Route path='/en/' exact element={<><Navbar lang='en'/><Landing lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/chapter/:chapterSlug' exact element={<><Navbar lang='en'/><Chapter lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/contact/' exact element={<><Navbar lang='en'/><Contact lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/events/' exact element={<><Navbar lang='en' /><Events lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/event/:eventSlug/' exact element={<><Navbar lang='en' /><Event lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/about/' exact element={<><Navbar lang='en'/><About lang='en' /><Footer lang='en'/></>} />
            <Route path='/en/news/' exact element={<><Navbar lang='en'/><News lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/news/article/:newsSlug' exact element={<><Navbar lang='en'/><NewsArticle lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/event/' exact element={<><Navbar lang='en'/><Event lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/awards/' exact element={<><Navbar lang='en'/><Awards lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/ieee/' exact element={<><Navbar lang='en'/><Preloader lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/join-ieee/' exact element={<><Navbar lang='en'/><JoinIEEE lang='en'/><Footer lang='en'/></>} />
            <Route path='/en/ieee2/' exact element={<Preloader lang='en'/>} />

            <Route path='/gr/' exact element={<><Navbar lang='gr'/><Landing lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/chapter/:chapterSlug' exact element={<><Navbar lang='gr'/><Chapter lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/contact/' exact element={<><Navbar lang='gr'/><Contact lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/events/' exact element={<><Navbar lang='gr' /><Events lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/event/:eventSlug/' exact element={<><Navbar lang='gr' /><Event lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/about/' exact element={<><Navbar lang='gr'/><About lang='gr' /><Footer lang='gr'/></>} />
            <Route path='/gr/news/' exact element={<><Navbar lang='gr'/><News lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/news/article/:newsSlug' exact element={<><Navbar lang='gr'/><NewsArticle lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/event/' exact element={<><Navbar lang='gr'/><Event lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/awards/' exact element={<><Navbar lang='gr'/><Awards lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/ieee/' exact element={<><Navbar lang='gr'/><Preloader lang='gr'/><Footer lang='gr'/></>} />
            <Route path='/gr/join-ieee/' exact element={<><Navbar lang='gr'/><JoinIEEE lang='gr'/><Footer lang='gr'/></>} />

            <Route path='*' element={<><NotFoundPage /></>} />
        </Routes>
     </Router>
     
    </div>
  );
}

export default App;