import React from 'react'
import { Helmet } from 'react-helmet-async'

function LanguageMetas({lang}) {
  return (
    <Helmet>
            
            <html lang={lang === 'en' ? 'en': 'el_GR'}/>  
            {lang === 'gr' && <>
            <meta property="og:locale" content="el_GR" data-rh="true"/>
            <meta property="og:locale:alternate" content="en_US" data-rh="true"/>         
            </>}

            {lang === 'en' && <>
            <meta property="og:locale" content="en_US" data-rh="true"/>
            <meta property="og:locale:alternate" content="el_GR" data-rh="true"/>
            </>}

    </Helmet>
  )
}

export default LanguageMetas