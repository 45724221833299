import React, {useEffect} from 'react'
import './JoinIEEE.css'
import CoolDivider from '../components/CoolDivider'
import LandingMetas from '../metas/LandingMetas'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
function JoinIEEE({lang}) {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <>
    <LandingMetas lang={lang} keepTitle={false} />
    <Helmet><title>{lang === 'en' ? 'Join IEEE' : 'Γίνε μέλος'} - IEEE NTUA Student Branch</title></Helmet>
    <Helmet>
      <meta name="twitter:title" 
      content={`${lang === 'en' ? 'Join IEEE' : 'Γίνε μέλος'} - IEEE NTUA Student Branch`}
      data-rh="true"/>
    </Helmet>
    <Helmet>
      <meta property="og:title" 
      content={`${lang === 'en' ? 'Join IEEE' : 'Γίνε μέλος'} - IEEE NTUA Student Branch`}
      data-rh="true" />
    </Helmet>


    <div className='page-container'>
        <h1 className='cool-title'>{lang === 'en' ? 'JOIN IEEE' : 'ΓΙΝΕ ΜΕΛΟΣ'}</h1>
        <div className='join-ieee-page-container'>
        <div className='join-ieee-container join-ieee'>
                <section>
                        <h2 className='title-with-hr'>Join IEEE</h2>

                        <div className='join-ieee-body'>
                        {lang === 'en' ? 
                        <>
                        <p>Joining IEEE provides a student with all the benefits of a regular member with the advantage of paying a much lower fee. 
                          IEEE provides the <mark>world's best technological information</mark> and as a member you too have the opportunity to learn about the latest 
                          technological developments. 
                          IEEE, through its cutting-edge topics and participation in various professional activities, conferences, etc., 
                          enables you to keep up to date with the advances in technology and your industry, the latest industry trends and, most importantly, 
                          ultimately helps you in your career.</p>
                        
                        <h2 className='list-title'>As a member:</h2>
                        
                        <ul>
                          <li>You receive by mail the award-winning monthly IEEE Spectrum magazine, which includes articles on the state of the profession, 
                            career and education opportunities, and features on the latest technological advances and their applications.</li>
                          <li>You have free online access to POTENTIALS magazine for students and young professionals.</li>
                          <li>You can take part in student competitions organized by IEEE, such as the Student Paper Contest, and distinguish yourself at an international level.</li>
                          <li>You can apply for various scholarships.</li>
                          <li>You have access to European and international job-offer directories and career promotion tools.</li>
                          <li>You can participate in student conferences and exchange knowledge and views with other members in Greece or abroad.</li>
                          <li>You can participate in all international scientific conferences organized by IEEE at special student rates.</li>

                        </ul>
                        <p>Most importantly, you can create a <mark>positive impact on society</mark> by being part of an organization that strives for advancing technology for the benefit of humanity.</p>
                        </>
                        :
                        <>
                          <p>Η εγγραφή στο ΙΕΕΕ παρέχει σε έναν φοιτητή όλα τα οφέλη ενός τακτικού μέλους με το πλεονέκτημα της καταβολής πολύ χαμηλότερης συνδρομής. 
                          Το IEEE παρέχει τις <mark>καλύτερες τεχνολογικές πληροφορίες στον κόσμο</mark> και ως μέλος έχετε κι εσείς την ευκαιρία να μάθετε για τις τελευταίες τεχνολογικές εξελίξεις. 
                          Το ΙΕΕΕ, μέσω της πρωτοποριακής θεματολογίας του και της συμμετοχής του σε διάφορες επαγγελματικές δραστηριότητες, συνέδρια κ.λπ. 
                          σας δίνει τη δυνατότητα να ενημερώνεστε για τις εξελίξεις στην τεχνολογία και στον κλάδο σας, 
                          για τις τελευταίες τάσεις του κλάδου και, το σημαντικότερο, τελικά σας βοηθάει στην καριέρα σας.
                          </p>

                          <h2 className='list-title'>Σαν μέλος:</h2>

                          <ul>
                            <li>Λαμβάνετε ταχυδρομικώς το βραβευμένο μηνιαίο περιοδικό IEEE Spectrum, το οποίο περιλαμβάνει άρθρα σχετικά με την κατάσταση του επαγγέλματος, 
                              ευκαιρίες σταδιοδρομίας και εκπαίδευσης, 
                              καθώς και άρθρα σχετικά με τις τελευταίες τεχνολογικές εξελίξεις και τις εφαρμογές τους.</li>
                            <li>Έχετε δωρεάν ηλεκτρονική πρόσβαση στο περιοδικό POTENTIALS για φοιτητές και νέους επαγγελματίες.</li>
                            <li>Μπορείτε να λάβετε μέρος σε φοιτητικούς διαγωνισμούς που διοργανώνει το ΙΕΕΕ, 
                              όπως ο φοιτητικός διαγωνισμός εργασιών και να διακριθείτε σε διεθνές επίπεδο.</li>
                            <li>Μπορείτε να υποβάλετε αίτηση για διάφορες υποτροφίες.</li>
                            <li>Έχετε πρόσβαση σε ευρωπαϊκούς και διεθνείς καταλόγους προσφορών εργασίας και σε εργαλεία προώθησης της καριέρας σας.</li>
                            <li>Μπορείτε να συμμετάσχετε σε φοιτητικά συνέδρια και να ανταλλάξετε γνώσεις και απόψεις με άλλα μέλη στην Ελλάδα ή στο εξωτερικό.</li>
                            <li>Μπορείτε να συμμετέχετε σε όλα τα διεθνή επιστημονικά συνέδρια που διοργανώνει το IEEE με ειδικές χαμηλές τιμές για φοιτητές.</li>
                          </ul>
                        
                        <p>Πιο σημαντικό απ' όλα, μπορείς να δημιουργήσεις <mark>θετική επίδραση στην κοινωνία</mark> ενώ είσαι μέλος μιας οργάνωσης που προωθεί την τεχνολογία προς όφελος της ανθρωπότητας.</p>
                        </>

                          
                        }
                        
                        

                        </div>
                        <a href='https://www.ieee.org/membership/join/index.html' rel="nofollow" target="_blank" className='join-ieee-button'>Join IEEE</a>
                        


                </section>
        </div>
        <div className='join-ieee-container'>
            <section className='volunteer'>
              {lang === 'en' ? 
              <>
              <h2 className='title-with-hr'>Become a Volunteer</h2>

<div className='join-ieee-body'>
<h2 className='list-title'>If you are interested in:</h2>
<ul>
 <li>Deepening your knowledge in the field of computer science and electrical engineering.</li>
 <li>Working in a team environment.</li>
 <li>Creating exciting projects with your peers.</li>
 <li>Brainstorming new and innovative ideas.</li>
 <li>Networking with professionals in the field.</li>
 <li>Organizing events and workshops.</li>
 <li>Being part of an active student community.</li>
</ul>

<p>Then you should join our student branch!</p>
<p>Within the branch you will have the opportunity to work in teams regarding <mark>projects</mark> like:</p>
<ul>
 <li>Website development</li>
 <li>Robotic arm</li>
 <li>Smart city</li>
 <li>Machine learning in medical imaging</li>
</ul>
    <p>You will also take part in many competitions and participate in events, workshops, and talks presenting new scientific innovations.</p>

  <p>Any and all ideas are welcome, and if there’s something that you thought of but we haven’t done yet, we can start it together.</p>

  <p>If you feel as if you "don’t know enough" to join, don’t let that deter you; we are all happy to help and were in your place too not that long ago.</p>
    </div>
              </>
              : <>
               <h2 className='title-with-hr'>Γίνε Εθελοντής</h2>
               <div className='join-ieee-body'>
                    <h2 className='list-title'>Εάν ενδιαφέρεστε για:</h2>
                    <ul>
                      <li>Εμβάθυνση των γνώσεών σας στον τομέα της επιστήμης του Ηλεκτρολόγου Μηχανικού & του Μηχανικού Υπολογιστών
                      </li>
                      <li>Να εργαστείτε σε ένα ομαδικό περιβάλλον
                      </li>
                      <li>Να δημιουργείτε συναρπαστικά έργα με τους συναδέλφους σας
                      </li>
                      <li>Να ανακαλύψετε και να ανταλλάξετε νέες και καινοτόμες ιδέες
                      </li>
                      <li>Δικτύωση με επαγγελματίες του χώρου
                      </li>
                      <li>Διοργάνωση εκδηλώσεων και workshops
                      </li>
                      <li>Συμμετοχή σε μια ενεργή φοιτητική κοινότητα
                      </li>
                    </ul>

                    <p>Τότε πρέπει να γίνετε μέλος του φοιτητικού μας παραρτήματος!</p>
                    <p>Στο πλαίσιο του παραρτήματος θα έχετε την ευκαιρία να εργαστείτε σε ομάδες σχετικά με <mark>projects</mark> όπως:</p>
                    <ul>
                      <li>Ανάπτυξη Ιστοσελίδων</li>
                      <li>Κατασκευή ενός Ρομποτικού Βραχίονα</li>
                      <li>Σχεδιασμός μιας Έξυπνης Πόλης</li>
                    </ul>

                    <p>Θα λάβετε επίσης μέρος σε πολλούς <mark>διαγωνισμούς</mark> και θα συμμετάσχετε σε <mark>εκδηλώσεις</mark>, <mark>εργαστήρια</mark> και <mark>ομιλίες</mark>, παρουσιάζοντας νέες επιστημονικές καινοτομίες.
                    </p>

                    <p>Όλες οι ιδέες είναι ευπρόσδεκτες- και αν υπάρχει κάτι που σκεφτήκατε και δεν το έχουμε κάνει ακόμα- μπορούμε να το ξεκινήσουμε μαζί.
                    </p>

                    <p>Αν αισθάνεστε ότι "δεν ξέρετε αρκετά" για να συμμετάσχετε- μην το αφήσετε να σας αποθαρρύνει- είμαστε όλοι ευτυχείς να βοηθήσουμε και ήμασταν κι εμείς στη θέση σας πριν από λίγο καιρό.</p>
                    {/* <p>Συμπληρώστε την παρακάτω φόρμα για να γίνετε μέλος:</p> */}

                  </div>
              </>}
                
                    <a href='https://linktr.ee/ieee_ntua_chapters' target="_blank" className='join-ieee-button reversed'>Become a Volunteer</a>


            </section>
        </div>
        </div>
    </div>
    </>
  )
}

export default JoinIEEE