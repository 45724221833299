import React from 'react';
import { Link } from 'react-router-dom'
import './Footer.css';
import IeeeLogo from '../assets/images/ieee_logo_optimized.png';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Footer = ({lang}) => {
    
        return (
            <footer>
              <br/>
              <h2 className='footer-title'> &emsp; IEEE NTUA STUDENT BRANCH</h2>
            
                <div id='container'>
            {/*1*/}<div> <h3>{lang=='gr'? 'Χρήσιμοι σύνδεσμοι':'Useful Links'}</h3>  
                              <br/> <Link to={`/${lang}/`}> {lang==='gr' ? 'Αρχική' : 'Home'} </Link> 
                              <br/> <Link to={`/${lang}/news`}>{lang==='gr' ? 'Νέα' : 'News'}</Link>
                              <br/> <Link to={`/${lang}/events`}>{lang=='gr' ? 'Εκδηλώσεις':'Events'} </Link>
                              <br/> <Link to={`/${lang}/awards`}>{lang==='gr' ? 'Βραβεία' : 'Awards'}</Link>
                              <br/> <Link to={`/${lang}/contact`}>{lang==='gr' ? 'Επικοινωνία' : 'Contact'}</Link>
                              <br/> <Link to={`/${lang}/about`}>{lang==='gr' ? 'Σχετικά με εμάς' : 'About'}</Link> 
                              <br/> <Link to={`/${lang}/join-ieee`}>{lang=='gr'? 'Εγγραφή':'Join IEEE'}</Link>
                  </div>
                  {/*2*/}  <div><h3>{lang === 'en'? 'Chapters' : 'Ομάδες'}</h3> 

                    <br/> <Link to={`/${lang}/chapter/computer-society/`}>Computer Society</Link> 
                    <br/> <Link to={`/${lang}/chapter/robotics-automation-society/`}>Robotics & Automation Society</Link>
                    <br/> <Link to={`/${lang}/chapter/power-energy-society/`}>Power & Energy Society</Link> 
                    <br/> <Link to={`/${lang}/chapter/engineering-in-medicine-biology-society/`}>Engineering in Medicine & Biology Society</Link> 
                    <br/> <Link to={`/${lang}/chapter/communications-society/`}>Communications Society</Link>

                  </div>
                  


                  {/*4*/}  <div><h3>{lang=='gr'? 'Διεύθυνση':'Address'}</h3> 
                    <br/> {lang=='gr'? 'Πολυτεχνειούπολη Ζωγράφου, Ηρώων Πολυτεχνείου 9, Κέντρο Πληροφορικής':'Zografou Campus, Iroon Polytechniou 9, Center of Informatics'}
                  </div>
                  
                  {/*5*/}  <div id='icons-column'> 
                    <img src={IeeeLogo} width="120" />
                    <div className='icon-row'>

                      {/* <a href="https://www.facebook.com/IeeeNtuaSB/" target="_blank"><i className="fa-brands fa-facebook"></i> </a>                        */}
                      <a href="https://www.facebook.com/IeeeNtuaSB/" target="_blank"><FacebookIcon/> </a>                       

                      {/* <a href='https://www.instagram.com/ieee_ntua_sb/' target="_blank"><i className="fa-brands fa-instagram"></i></a> */}
                      <a href='https://www.instagram.com/ieee_ntua_sb/' target="_blank"><InstagramIcon/></a>

                      {/* <a href='https://www.linkedin.com/company/ieee-ntua-student-branch/' target="_blank"><i className="fa-brands fa-linkedin"></i></a> */}
                      <a href='https://www.linkedin.com/company/ieee-ntua-student-branch/' target="_blank"><LinkedInIcon/></a>

                      {/* <a href="mailto:ieee.ntua.sb@gmail.com" target="_blank"> <i className="fa-solid fa-envelope"></i> </a>        */}
                      <a href="mailto:ieee.ntua.sb@gmail.com" target="_blank"> <EmailIcon/> </a>       

                      {/* <a href="https://goo.gl/maps/M2zEcpkcfHD4aMif8" target="_blank"> <i className="fa-sharp fa-solid fa-location-dot"></i></a>  */}
                      <a href="https://goo.gl/maps/M2zEcpkcfHD4aMif8" target="_blank"> <LocationOnIcon/></a> 

                    
                    </div>

                      </div>
                  </div>
            
            <hr/>
            <p>© 2023 IEEE NTUA SB – All rights reserved.</p>
            </footer>
        );

    };

export default Footer;