import React from 'react'
import './Preloader.css'


function Preloader() {
  return (
    <div className='preloader2-wrapper'>
        <div className='preloader2'>
        </div>
    </div>
  )
}

export default Preloader