import React, { useEffect, useState } from 'react'
import './Chapter.css'
import EventCard from '../components/EventCard';
import axiosInstance from '../api/api';
import { useParams } from 'react-router-dom';
import Preloader from './Preloader';
import PersonCard from '../components/PersonCard';
import { useLocation } from 'react-router-dom';
import ChapterMetas from '../metas/ChapterMetas';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TikTokIcon from '../assets/images/chapters/tiktok.svg'

function Chapter({lang}) {

    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    // const [themeColor, setThemeColor] = useState(hexToRgbA("#00629B"))
    const {chapterSlug} = useParams();


    async function fetchChapter() {
        const response = await axiosInstance.get(`/society/${chapterSlug}?lang=${lang}`);

        setLoading(false);
        setChapterData(response?.data?.data);
    }

    useEffect( () => {
        setLoading(true)
        fetchChapter();
    },[chapterSlug, lang])

    const [loading, setLoading ] = useState(true);
    const [chapterData, setChapterData] = useState(null);

    function hexToRgbA(hex, rgba){
        var c;
        if (hex[0] !== '#') hex = '#' + hex;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            if (rgba)
                return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.6)';
            else return 'rgb('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+')'
        }
        throw new Error('Bad Hex');
    }


    if (loading) return <Preloader />
  return (
    <>
    <ChapterMetas 
        chapter={chapterData?.title}
        description={chapterData?.body}
        imageURI={chapterData?.hero_image}
    />


    <div className='chapter-top-hero'>
        <div className='chapter-bg-image' style={{backgroundColor: hexToRgbA(chapterData?.page_color, true), backgroundImage: `url(${chapterData?.hero_image})`}}></div>
        <div className='chapter-bg-color'></div>
        <div className='chapter-top-hero-text'>
            <h1>IEEE {chapterData?.short_title} NTUA</h1>
            <button type='button' className='get-to-know-us' 
            data-hover="button" style={ { backgroundColor: hexToRgbA(chapterData?.page_color, false) } } onClick={() => document.querySelector(`#chapter-id`).scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })}>{lang === 'en' ? 'Get to know us' : 'Γνωριστε μας'}</button>
            <style>{`[data-hover="button"]:hover {
                background-color: #fff!important;
                color: ${hexToRgbA(chapterData?.page_color, false)}!important;
                border-color: ${hexToRgbA(chapterData?.page_color, false)}!important;
            }`}</style>
        </div>
    </div>

    <div className='chapter-page' id='chapter-id'>
       <div className='chapter-padding'>
       <div className='chapter-hero'>
       <h1 className='cool-title' style={{backgroundColor: hexToRgbA(chapterData?.page_color, false)}}>
        {chapterData.title} Chapter
        </h1> 
        {/* <div className='chapter-container'>
            <div className='chapter-details'>
                <div className='chapter-row'>
                    <img src={CSLogo} alt='Computer Science Chapter' width="150" />
                    <p>Do you like IT? Are you interested in Machine Learning, Software Development or Competitive Programming? cs-logoWould you like to learn about Ethical Hacking and Quantum Computing? If the answer is yes then the Computer Society Chapter of the IEEE NTUA Student Branch is for you. Our goal is to approach various fields of IT, through participation in competitions, and execution of projects. Thus we have the opportunity to apply the theoretical knowledge of the school on hands on applications, as well as to exchange opinions and meet people with the same passion as us. At the same time, through the organization of workshops and speeches, we try to share the knowledge we acquire with the entire student community.</p>
                </div>
            </div>
        </div> */}
        <section className='chapter-details'>
            <img src={chapterData?.logo} alt={chapterData?.title + " Chapter."} width="150" />
            <div className='chapter-details-section'>
                <div className='chapter-details-row'>
                <div className='chapter-social-media'>
                        <style>{`.chapter-social-media i:hover {
                        color: ${hexToRgbA(chapterData?.page_color, false)}!important;
                    }`}</style>
                                
                                {/* {chapterData?.facebook && <a target="_blank" href={chapterData?.facebook}><i className="fa-brands fa-facebook" ></i></a>} */}
                                {chapterData?.facebook && <a target="_blank" href={chapterData?.facebook}><FacebookIcon sx={{"&:hover": { color: hexToRgbA(chapterData?.page_color, false) } }}/></a>}

                                {/* {chapterData?.instagram && <a target="_blank" href={chapterData?.instagram}><i className="fa-brands fa-instagram"></i></a>} */}
                                {chapterData?.instagram && <a target="_blank" href={chapterData?.instagram}><InstagramIcon sx={{"&:hover": { color: hexToRgbA(chapterData?.page_color, false) } }}/></a>}

                                {chapterData?.linkedin && <a target="_blank" href={chapterData?.linkedin}><LinkedInIcon sx={{"&:hover": { color: hexToRgbA(chapterData?.page_color, false) } }}/></a>}
                                {/* {chapterData?.linkedin && <a target="_blank" href={chapterData?.linkedin}><i className="fa-brands fa-linkedin"></i></a>} */}
                                {chapterData?.email && <a target="_blank" href={"mailto:"+chapterData?.email}><EmailIcon sx={{"&:hover": { color: hexToRgbA(chapterData?.page_color, false) } }}/></a>}

                                {/* {chapterData?.email && <a target="_blank" href={"mailto:"+chapterData?.email}><i className="fa-solid fa-envelope"></i></a>} */}
                                <style>
                                    {`.chapter-social-media a:hover svg {
                                        fill: ${hexToRgbA(chapterData?.page_color, false)}!important;
                                    }`}
                                </style>
                                {chapterData?.tiktok && <a target="_blank" href={chapterData?.tiktok}><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M412.19 118.66a109.27 109.27 0 0 1-9.45-5.5 132.87 132.87 0 0 1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 0 1-35.22 55.56 68.8 68.8 0 0 1-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0-118 34.52 161.79 161.79 0 0 0-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 0 0 115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61Z"/></svg></a>}

                                {/* {chapterData?.tiktok && <a target="_blank" href={chapterData?.tiktok}><i className="fa-brands fa-tiktok"></i></a>} */}
                                {/* <i class="fa-brands fa-tiktok"></i>
                                <a href = "mailto:ieee.ntua.sb@gmail.com"> <i class="fa-solid fa-envelope"></i> </a>
                                <a href="https://www.facebook.com/IeeeNtuaSB/"> <i class="fa-brands fa-facebook"></i> </a> 

                                <a href='#'><i class="fa-brands fa-instagram"></i></a>
                                <a href="https://goo.gl/maps/M2zEcpkcfHD4aMif8"> <i class="fa-brands fa-github"></i></a> 
                                <a></a> */}
                 </div>
                 {chapterData?.recruitment_form && 
                    <div className='chapter-join-link'>
                    <a href={chapterData?.recruitment_form} target="_blank" rel="nofollow">Join {chapterData?.short_title}!</a>
                    <style>
                        {`.chapter-join-link a {
                            background-color: #${chapterData?.page_color}!important;
                            color: #fff!important;
                        }
                        .chapter-join-link a:hover {
                        color: #${chapterData?.page_color}!important;
                        background-color: #fff!important;
                    }`}
                    </style>
                </div>}
                 

                 </div>
                <div className='chapter-details-text' dangerouslySetInnerHTML={{__html: chapterData?.body}}></div>
            </div>
        </section>
        </div>
        </div>



        {chapterData?.subgroups.length > 0 && <section className='chapter-section chapter-subgroups' style={{backgroundColor: hexToRgbA(chapterData?.page_color, false)}}>
            <h1 className='chapter-section-title'>{chapterData?.subgroup_label}</h1>
            <div className='chapter-subroups-wrapper'>
                
                {/* <div className='chapter-subgroup'>
                    <img src={'https://bigblue.academy/images/image/blog/1-3cxboknql4qs-lryht3pqw.png'} alt='subgroup img'  width={150} />
                    <h3 className='subgroup-title'>Machine Learning</h3>
                    <p>Lorem ipsum dolor sit amet. Sed doloremque eaque ad voluptas voluptas ea sunt magni et iste illum non dolor beatae id accusamus velit sed autem dignissimos? Sit optio fugiat et iure aperiam et nisi sint.</p>
                </div> */}

                {chapterData?.subgroups.map((subgroup, index) => { return <div key={index} className='chapter-subgroup'>
                    <img src={subgroup.thumbnail} alt={subgroup?.title + "."}  width={150} loading='lazy' />
                    <h3 className='subgroup-title'>{subgroup.title}</h3>
                    <p>{subgroup.body}</p>
                </div>
                })}




               
            </div>
        </section>}



        {chapterData?.events.length > 0 && <div className='chapter-padding'>
        <section className='chapter-section chapter-events'>
            <h1 className='chapter-section-title title-with-hr'>{lang === 'en' ? 'Latest Events' : 'Προσφατα Event'}</h1>
            {/* <CoolDivider /> */}
            <style>
                {`
 
                .title-with-hr:before {
                    background: linear-gradient(to right,#${chapterData?.page_color},	#fff)!important;
                }`}
            </style>
        
                <div className='events-container chapter-events-wrapper'>
                
               


                    {chapterData?.events.map((event, index) => {return <EventCard
                        key={index}
                        lang={lang}
                        title={event?.title} 
                        imageUrl={event?.mobile_thumbnail} 
                        excerpt={event?.excerpt}
                        societies={event?.societies}
                        eventTime={event?.event_time} slug={event?.slug}
                        expired={event.expired}
                        place={event.place}
                        />
                    })}
                    

                    {/* <EventCard 
                        lang={lang}
                        title={"1st IEEE Greece Mentoring Event"} 
                        imageUrl={"https://scontent.fath6-1.fna.fbcdn.net/v/t39.30808-6/317084781_5600227896727136_1930716385921819817_n.jpg?stp=c0.15.1065.555a_dst-jpg_p600x600&_nc_cat=105&ccb=1-7&_nc_sid=340051&_nc_ohc=bYh4evE5VicAX9aMvsW&_nc_ht=scontent.fath6-1.fna&oh=00_AfDYYuQzfCApKCX-EtR2GVYskmajnagHjvSV336JJ_2Ntg&oe=6402FD6D"} 
                        excerpt={"Our goal is the appeal of various fields of IT, through participation in competitions, and projects are carried out."} 
                        eventTime={"2023-03-01 02:35:16.817537"} slug={"random"}/>


                    <EventCard 
                        lang={lang}
                        title={"1st IEEE Greece Mentoring Event"} 
                        imageUrl={"https://scontent.fath6-1.fna.fbcdn.net/v/t39.30808-6/317084781_5600227896727136_1930716385921819817_n.jpg?stp=c0.15.1065.555a_dst-jpg_p600x600&_nc_cat=105&ccb=1-7&_nc_sid=340051&_nc_ohc=bYh4evE5VicAX9aMvsW&_nc_ht=scontent.fath6-1.fna&oh=00_AfDYYuQzfCApKCX-EtR2GVYskmajnagHjvSV336JJ_2Ntg&oe=6402FD6D"} 
                        excerpt={"Our goal is the appeal of various fields of IT, through participation in competitions, and projects are carried out."} 
                        eventTime={"2023-03-01 02:35:16.817537"} slug={"random"}/> */}
                </div>           
        </section>
        </div>}


{ chapterData?.people.length > 0 &&  <div className='chapter-padding'>
        <section className='chapter-section chapter-people'>
            <h1 className='chapter-section-title title-with-hr'>{chapterData?.short_title} Board</h1>
            {/* <CoolDivider/> */}

            <style>
            {`.our-team .picture::before {
                background-color: #${chapterData?.page_color}!important;
                }
                
                .our-team .picture::after {

                    background-color: #${chapterData?.page_color}!important;

                  }

                  
                  
                  .our-team .social {

                    background-color: #${chapterData?.page_color}!important;

                  }

                  .our-team .social li a:hover {
                    color: #${chapterData?.page_color}!important;
                  }
                  
                  `}
            </style>
            <section className='people-container'>
                <section className='people-row'>
                    {chapterData?.people.map((person, index) => {
                        return  <>
                        <PersonCard 
                        key={index}
                        profileURL={person?.profile_pic}
                        fullName={person?.full_name}
                        role={person?.role}
                        linkedin={person?.linkedin}
                        facebook={person?.facebook}
                        instagram={person?.instagram}
                        email={person?.email}
                        />
                        <style>
                            {`.our-team {
                                box-shadow: 8px 8px #${chapterData?.page_color};
                            }
                            .our-team:hover {
                                box-shadow: 4px 4px #${chapterData?.page_color};
                            }
                            `}
                        </style>
                        </>
                    })}
                </section>
            </section>

        </section>
        </div>}
        

    </div>
  </>
  )
}

export default Chapter