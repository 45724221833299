import React from 'react'
import { Helmet } from "react-helmet-async"


function ChapterMetas({chapter, description, imageURI}) {
  return (
    <Helmet>
            <meta property="description" content={description} data-rh="true"/>
            
            <meta property="og:type" content="website" />
            
            <meta property="og:title" content={`${chapter} - IEEE NTUA Student Branch`} data-rh="true"/>
            
            <meta property="og:description" data-rh="true" content={description} />
            
            <meta property="og:image" content={imageURI} data-rh="true"/>
            <meta property="og:site_name" content={`${chapter} - IEEE NTUA Student Branch`} data-rh="true"/>
            <meta property="og:image:alt" content={`${chapter} - IEEE NTUA Student Branch`} data-rh="true"/>
            <meta name="twitter:card" content="summary_large_image" data-rh="true"/>
            <meta name="twitter:site" content={`${chapter} - IEEE NTUA Student Branch`} data-rh="true"/>

            <meta name="twitter:title" content={`${chapter} - IEEE NTUA Student Branch`} data-rh="true"/>

            <meta name="twitter:description" data-rh="true"  content={description} />

            <meta name="twitter:image" content={imageURI} data-rh="true"/>

            <title>{`${chapter} - IEEE NTUA Student Branch`}</title>
            
    </Helmet>
  )
}

export default ChapterMetas