import React, {useEffect, useState} from 'react'
import Hero from '../components/Hero'
import IEEEMaster from '../assets/images/landing/ieee_master.png'
import NTUAIEEE from '../assets/images/ieee_logo_optimized.png'
import MembersImage from '../assets/images/landing/members.svg'
import PersonCard from '../components/PersonCard'
import TeamsImage from '../assets/images/landing/teams3.svg'
import EventsImage from '../assets/images/landing/events.svg'
// import VolunteersImage from '../assets/images/landing/teams.svg'
import VolunteersImage from '../assets/images/landing/volunteers.svg'
import { useLocation } from 'react-router-dom'
import './Landing.css'
import LandingMetas from '../metas/LandingMetas'
import axiosInstance from '../api/api'
import ChapterCard from '../components/ChapterCard'
import Preloader from './Preloader'

// import RasImage from '../assets/images/landing/chapter-images/ras.png'
// import CsImage from '../assets/images/landing/chapter-images/cs.png'
// import PesImage from '../assets/images/landing/chapter-images/pes.png'
// import ComsocImage from '../assets/images/landing/chapter-images/comsoc.png'
// import EmbsImage from '../assets/images/landing/chapter-images/emb.png'




export default function Landing({lang}) {


  const [board, setBoard] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchBoard() {
    setLoading(true);
    const response = await axiosInstance.get(`/board/?lang=${lang}`);    
    setBoard(response?.data?.data);
    setLoading(false);
  }


  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  useEffect( () => {
    fetchBoard();
  }, [lang])


  if (loading) return <Preloader />
  return (
    <>
    <LandingMetas lang={lang} keepTitle={true} />
    <Hero/>

    <section className='landing-section'>

      {/* <h1>Bringing engineering from Athens, to the World</h1> */}
      <h1 className='title-with-hr shine'>IEEE NTUA: Where knowledge meets passion!</h1>
      {/* <div className="elementor-widget-container">
							<div className="aux-bus2-title-divider">&nbsp;</div></div> */}
      {/* <div className='who-are-we-description'>
        <img src={IEEEMaster} alt='IEEE Master Brand' width="150"/>
        <div className='who-are-we-text'>
          <h3>Bringing engineering from Athens, to the World</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci diam, lacinia tempor elit sed, hendrerit condimentum tellus. Suspendisse semper ligula et diam semper, id blandit dui fringilla. Phasellus viverra risus odio, sit amet congue ligula tincidunt vel. Donec ultricies tortor ac cursus viverra. Nullam ultricies dapibus dolor et pharetra. Sed laoreet consequat lacus nec viverra. Curabitur et convallis nisi. In sit amet aliquet enim, sed tincidunt sapien.</p>
        </div>
      </div> */}

      <div className='who-are-we'>
          <div className='row first-row'>
            <div className='text'>
              <h3><mark>{lang === 'en' ? 'What is IEEE?' : "Τι είναι το IEEE;"}</mark></h3>
              
            { lang === 'en' ?
                        <p>IEEE is the largest technical professional association in the world, focused on "advancing technology for the benefit of humanity". 
                          It publishes a wide range of journals and standards in the fields of electrical engineering and computer science. 
                          Most notably, IEEE developed the 802.11 wireless standard (also known as Wi-Fi) and is a leading organization in the technological sector.
                        </p> : 
                        <p>Το IEEE είναι το Ινστιτούτο Ηλεκτρολόγων και Ηλεκτρονικών Μηχανικών, η μεγαλύτερη τεχνική επαγγελματική ένωση στον κόσμο, που επικεντρώνεται στην "προώθηση της τεχνολογίας προς όφελος της ανθρωπότητας". 
                        Εκδίδει ένα ευρύ φάσμα περιοδικών και προτύπων στους τομείς της ηλεκτρολογίας και της επιστήμης των υπολογιστών. 
                        Για παράδειγμα, το ΙΕΕΕ ανέπτυξε το πρότυπο ασύρματης επικοινωνίας 802.11 (γνωστό και ως Wi-Fi) και αποτελεί κορυφαίο οργανισμό στον τεχνολογικό τομέα.
                      </p>
            }



            
            </div>
            <img src={IEEEMaster} alt='IEEE - Advancing Technology for Humanity.'/>

          </div>

          <div className='row second-row'>
            <div className='text'>
              <h3><mark>{lang === 'en' ? "Who are we?" : 'Ποιοί είμαστε;'}</mark></h3>
              {lang === 'en' ?
              <p>IEEE NTUA Student Branch is a volunteer organization at the National Technical University of Athens, led by students. 
              The branch is made up of five society chapters: Computer Society (CS), Robotics and Automation Society (RAS), 
              Power and Energy Society (PES), Engineering in Medicine and Biology Society (EMBS), and Communications Society (ComSoc). 
              We collaborate, create projects, organize workshops and talks, and participate in various competitions. 
              IEEE NTUA aims to inspire other students and create an active community based on creativity and innovation.</p>
              :
              <p>
                Το IEEE NTUA Student Branch είναι μια φοιτητική εθελοντική οργάνωση στο Εθνικό Μετσόβιο Πολυτεχνείο. 
                Αποτελείται από πέντε Society Chapters: Computer Society (CS), Robotics and Automation Society (RAS), Power and Energy Society (PES), 
                Engineering in Medicine and Biology Society (EMBS) και Communications Society (ComSoc). Σε αυτό, συνεργαζόμαστε και δημιουργούμε projects, 
                διοργανώνουμε workshops, ομιλίες και συμμετέχουμε σε διάφορους διαγωνισμούς. Στόχος μας είναι να εμπνεύσουμε άλλους φοιτητές και να δημιουργήσουμε μια 
                ενεργή κοινότητα βασισμένη στη δημιουργικότητα και την καινοτομία.
              </p>}

              
            
            </div>
            <img src={NTUAIEEE} alt='IEEE NTUA - Where knowledge meets passion!'/>

          </div>

      </div>
    </section>


      <section className='metrics'>
        <h1 className='outlined'>{ lang === 'en' ? 'Meet our team' : 'Η Ομάδα μας'}</h1>
        <ul className='metrics-list'>
          <li className='metric'>
            <img src={MembersImage} alt='Members' />
            <strong>{lang === 'en' ? 'Members' : 'Μέλη'}</strong>
            <h4 className='count'>150+</h4>
          </li>

          <li className='metric'>
            <img src={VolunteersImage} alt='Volunteers' />
            <strong>{lang === 'en' ? 'Volunteers' : 'Εθελοντές'}</strong>
            <h4 className='count'>100+</h4>
          </li>

          <li className='metric'>
            <img src={EventsImage} alt='Events' />
            <strong>{lang === 'en' ?'Events' : 'Εκδηλώσεις'}</strong>
            <h4 className='count'>50+</h4>
          </li>

          <li className='metric'>
            <img src={TeamsImage} alt='Teams' />
            <strong>{lang === 'en' ? 'Teams' : 'Ομάδες'}</strong>
            <h4 className='count'>10+</h4>
          </li>

        </ul>
      </section>


            <section className='chapter-cards-section'>
              <h1 className='title-with-hr'>Chapters</h1>
              <div className='chapter-cards-container'>

              <ChapterCard 
              image={"https://ieee.ntua.gr/media/media/society/computer-society/computer-society.png"}
              color={"#00629A"}
              title={"Computer Society"}
              shortTitle={"CS"}
              slug={'computer-society'}
              lang={lang}
              />
              <ChapterCard 
              image={"https://ieee.ntua.gr/media/media/society/robotics-automation-society/robotics-automation-society.png"}
              color={"#BA0C2F"}
              title={"Robotics & Automation Society"}
              shortTitle={"RAS"}
              slug={'robotics-automation-society'}
              lang={lang}
              />
              <ChapterCard 
              image={"https://ieee.ntua.gr/media/media/society/power-energy-society/power-energy-society.png"}
              color={"#00843D"}
              title={"Power & Energy Society"}
              shortTitle={"PES"}
              slug={'power-energy-society'}
              lang={lang}
              />
              <ChapterCard 
              image={"https://ieee.ntua.gr/media/media/society/communications-society/communications-society.png"}
              color={"#FFD100"}
              title={"Communications Society"}
              shortTitle={"COMSOC"}
              slug={'communications-society'}
              lang={lang}
              />
              <ChapterCard 
              image={"https://ieee.ntua.gr/media/media/society/engineering-in-medicine-biology-society/engineering-in-medicine-biology-society.png"}
              color={"#FFA300"}
              title={"Engineering In Medicine & Biology Society"}
              shortTitle={"EMBS"}
              slug={'engineering-in-medicine-biology-society'}
              lang={lang}
              />


              </div>
              {/* <ChapterCards /> */}
            </section>

     {board && board.length > 0 && 
     <section className='executive-board-people'>

        <section className='people-container'>
        <h1 className='title-with-hr'>Executive Board</h1>

          <div className='people-row'>
          {board.map((person, index) => {return <PersonCard 
             key={index}
             profileURL={person?.profile_pic}
             fullName={person?.full_name}
             role={person?.role}
             linkedin={person?.linkedin}
             facebook={person?.facebook}
             instagram={person?.instagram}
             email={person?.email}
            />})}       

            { /*
            <PersonCard 
              profileURL="https://ieeentuaathens.eu.pythonanywhere.com/media/media/people/ieee-ntua-sb-board/vangelis-froudakis-88589/vangelis-froudakis-88589.jpg"
              fullName={lang === 'en' ? 'Vangelis Froudakis' : "Βαγγέλης Φρουδάκης"}
              role="Chair"
              linkedin="https://www.linkedin.com/in/vaggelisfroudakis"
              facebook="https://www.facebook.com/vaggelis.froudakis"
            />
            <PersonCard 
              profileURL="https://ieeentuaathens.eu.pythonanywhere.com/media/media/people/ieee-ntua-sb-board/christoforos-vardakis-20346/christoforos-vardakis-20346.jpg"
              fullName={lang === 'en' ? 'Christoforos Vardakis' :"Χριστόφορος Βαρδάκης"}
              role="Vice Chair"
              linkedin="https://www.linkedin.com/in/christoforosvardakis/"
              facebook="https://www.facebook.com/xrisbard/"
              instagram="https://www.instagram.com/chris__bard/"
              email="xrist.vardakis@gmail.com"
            />
            <PersonCard 
            // key={index}
              profileURL="https://ieeentuaathens.eu.pythonanywhere.com/media/media/people/ieee-ntua-sb-board/andreas-magoutas-99321/andreas-magoutas-99321.jpg"
              fullName={lang === 'en' ? 'Andreas Magoutas' : 'Ανδρέας Μαγκούτας'}
              role="Treasurer"
              facebook="https://www.facebook.com/profile.php?id=100030286673379"
            /> */}

          </div>
        </section>
      </section>}
      
    
    </>
  )
}
