import React from 'react'
import './ChapterCard.css'
import { Link } from 'react-router-dom'
function ChapterCard({image, title, shortTitle, color, slug, lang}) {
  return (
    <div class={`chapter-card ${shortTitle}-chapter-card`}>
        <style>{`
        .${shortTitle}-chapter-card {
            background-color: ${color}!important;
        }
        `}</style>
        <div class="product-image">
        <img src={image} alt={title+"."} draggable="false" loading="lazy"/>
        </div>
        <div class="product-info">
        <h2>{shortTitle}</h2>
        <div class="price">{title}</div>
        </div>
        <div class="btn">
        <Link class="buy-btn" to={`/${lang}/chapter/${slug}/`}>Visit {shortTitle}</Link>
        {/* <button class="fav">
            <svg class="svg" id="i-star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <path d="M16 2 L20 12 30 12 22 19 25 30 16 23 7 30 10 19 2 12 12 12 Z" />
            </svg>
        </button> */}
    </div>
  </div>
  )
}

export default ChapterCard