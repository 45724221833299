import React, { useEffect, useState } from 'react'
import Dropdown from '../components/Dropdown'
import EventCard from '../components/EventCard'
import Pagination from '../components/Pagination'
import SearchBar from '../components/SearchBar'
import './Events.css'
import axiosInstance from '../api/api'
import Preloader from './Preloader';
import { Link } from 'react-router-dom'
import NotFoundImage from '../assets/images/404.png'
import LandingMetas from '../metas/LandingMetas'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'


export default function Events({lang}) {  

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);



  async function fetchEvents() {
    const response = await axiosInstance.get(`/events/?lang=${lang}&o=${order}&search=${searchTerm}&page=${page}`);
    setEvents(response?.data?.data)
    setTotalPages(response?.data?.total_pages);
    setLoading(false)

  }
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState(1)


  useEffect( () => {
    setLoading(true);
    fetchEvents();
  }, [page, searchTerm, order, lang])


  const DROPDOWN_MENU = [
    {
      'value':1,
      'en': 'Time Descending',
      'gr': 'Πιο Πρόσφατα'
    },
    {
      'value':2,
      'en': 'Time Ascending',
      'gr': 'Παλαιότερα'
    },
    {
      'value':3,
      'en': 'Ending Sooner',
      'gr': 'Λήγουν Συντομότερα'
    },
  ]


  if (loading) return <Preloader />;


  return (
    <>
    <LandingMetas lang={lang} />
    <Helmet><title>{lang === 'en' ? 'Events' : 'Εκδηλώσεις'} - IEEE NTUA Student Branch</title></Helmet>
    <Helmet>
      <meta name="twitter:title" 
      content={`${lang === 'en' ? 'Events' : 'Εκδηλώσεις'} - IEEE NTUA Student Branch`}
      data-rh="true"/>
    </Helmet>
    <Helmet>
      <meta property="og:title" 
      content={`${lang === 'en' ? 'Events' : 'Εκδηλώσεις'} - IEEE NTUA Student Branch`}
      data-rh="true" />
    </Helmet>


    <div className='page-container'>
      <h1 className='cool-title'>{lang === 'en' ? 'Events' : 'Εκδηλωσεις'}</h1>
    
    <div className='events-filters'>
      <Dropdown setOrder={setOrder} order={order} lang={lang} menu={DROPDOWN_MENU} />
      <SearchBar lang={lang} setSearchTerm={setSearchTerm} />
    </div>

    <Pagination page={page} setPage={setPage} totalPages={totalPages} />
    {searchTerm.length >= 1 && (lang === 'en' ? ('Searching for: '  + searchTerm) : ('Αναζήτηση για: ' + searchTerm)) }
    {/* {searchTerm.length >= 1 && 'Search Results for: ' + searchTerm}
    {<p>Ordering is: {order}</p>} */}
    
    <div className='events-container'>

      {events && events.length > 0 ? events.map((event, index) => {
        return <EventCard 
                  key={index}
                  lang={lang} 
                  imageUrl={event.mobile_thumbnail}
                  eventTime={event.event_time}
                  slug={event.slug} 
                  societies={event.societies}
                  title={event.title}
                  excerpt={event.excerpt}
                  expired={event.expired}
                  place={event.place}
              />

      }) : null}

      {/* <EventCard lang={lang}/>
      <EventCard lang={lang}/>
      <EventCard lang={lang}/>
      <EventCard lang={lang}/>
      <EventCard lang={lang}/>
      <EventCard lang={lang}/>
      <EventCard lang={lang}/>
      <EventCard lang={lang}/> */}
    </div>
    {(!events || events.length === 0) && <div className='events-not-found'>
            <img src={NotFoundImage} alt='Not Found' width={200} />
            <h1>No events found</h1>

            <Link to={`/${lang}`}>Go Home</Link>
        </div> }
    

    <Pagination page={page} setPage={setPage} totalPages={totalPages} />


    </div>
    </>

  )
}
