import React from 'react'
import './NotFoundPage.css'
import NotFoundImage from '../assets/images/404.png'
import { Link } from 'react-router-dom';
import LandingMetas from '../metas/LandingMetas';


function NotFoundPage({lang}) {
  return (
    <>
    <LandingMetas lang={lang} />
    <div className='error-container'>
        <h1 className='cool-title'>Page not found</h1>
        <img src={NotFoundImage} alt='404 Error' />
        <br/>
        <Link to={`/en/`}>Go Home</Link>
    </div>
    </>
  )
}

export default NotFoundPage