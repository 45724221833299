import React from 'react'
import { Helmet } from "react-helmet-async"


function NewsArticleMetas({description, title, imageURI}) {
  return (
    <Helmet>
            <meta property="description" content={description} data-rh="true"/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} data-rh="true"/>
            <meta property="og:type" content="article" />
            <meta property="og:description" data-rh="true" content={description} />
            <meta property="og:image" content={imageURI} data-rh="true"/>
            <meta property="og:site_name" content="IEEE NTUA Student Branch" data-rh="true"/>
            <meta property="og:image:alt" content={title} data-rh="true"/>
            <meta name="twitter:card" content="summary_large_image" data-rh="true"/>
            <meta name="twitter:site" content="IEEE NTUA Student Branch" data-rh="true"/>
            <meta name="twitter:title" content={title} data-rh="true"/>
            <meta name="twitter:description" data-rh="true" content={description} />
            <meta name="twitter:image" content={imageURI} data-rh="true"/>
            <title>{title} - IEEE NTUA Student Branch</title>
    </Helmet>
  )
}

export default NewsArticleMetas