import React from 'react'
import SearchImage from '../assets/images/search.svg'
import './SearchBar.css'



function SearchBar({lang, setSearchTerm}) {

  return (
    <div className='events-search-bar'>
        
        <form className='search-form' onSubmit={(e) => {
            e.preventDefault();
            setSearchTerm(e.target.searchBar.value);
        }}>
          
            <label htmlFor="searchBar">{lang=='en' ? 'Search:': 'Αναζήτηση:'} </label>
            <div className='search-bar'>
                <input type="text" name="searchBar" id='searchBar'  placeholder={lang === 'en' ? 'Search...' : 'Αναζήτηση...'}/>
                {/* <button type='submit'><img src={SearchImage} alt='search' /></button> */}
                <button type='submit' aria-label={lang === 'en'? 'Click here to search.' : 'Πατήστε εδώ για αναζήτηση.'}>{lang==='en' ? 'Search' : 'Αναζήτηση'}</button>
            </div>
        </form>
        
      </div>

  )
}

export default SearchBar