import axios from "axios";




const axiosInstance = axios.create({
    // baseURL: 'http://192.168.1.9:8000/api/',
    // baseURL: 'https://ieeentuaathens.eu.pythonanywhere.com/api/',
    baseURL: 'https://ieee.ntua.gr/api/',
})
export default axiosInstance;