import React, { useState, useEffect } from 'react'
import axiosInstance from '../api/api'
import './Contact.css'
import SocialMedia from '../components/SocialMedia';
import LandingMetas from '../metas/LandingMetas';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import SendIcon from '@mui/icons-material/Send';

export default function Contact({lang}) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [honeyPot, setHoneyPot] = useState(false);
  const [success, setSuccess] = useState('');
  const [err, setErr] = useState('');


  function handleHoneypotChange() {
    setHoneyPot(!honeyPot);
  }

  function clearFields() {
    setEmail('');
    setMessage('');
    setFirstName('');
    setLastName('');
    setSubject('')
    setHoneyPot(false)
  }

  async function postData(e) {
    e.preventDefault();
    setSuccess('');
    setErr('');
    const messageData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      subject: subject,
      message: message,
      honeypot: Boolean(honeyPot),
    }

    try {
    const response = await axiosInstance.post('/contact/', messageData,
      {headers: {
        'Content-Type': 'multipart/form-data'
      }});
    if (response.status === 200) {
      const successMessage = lang === 'en' ? 'Message has been received. Thank you for contacting us!' : 'Το μήνυμα στάλθηκε. Ευχαριστούμε που επικοινωνήσατε μαζί μας!'
      setSuccess(successMessage);
      clearFields();
    }
    } catch (err) {
      const errorMessage = lang === 'en' ? 'Something went wrong. Please try again later.' : "Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά αργότερα."
      setErr(errorMessage);
    }
    
    


  }


  return (
    <>
    <LandingMetas lang={lang} />
    <Helmet><title>{lang === 'en' ? 'Contact' : 'Επικοινωνία'} - IEEE NTUA Student Branch</title></Helmet>
    <Helmet>
      <meta name="twitter:title" 
      content={`${lang === 'en' ? 'Contact' : 'Επικοινωνία'} - IEEE NTUA Student Branch`}
      data-rh="true"/>
    </Helmet>
    <Helmet>
      <meta property="og:title" 
      content={`${lang === 'en' ? 'Contact' : 'Επικοινωνία'} - IEEE NTUA Student Branch`}
      data-rh="true" />
    </Helmet>
    
    <div className='page-container'>
      <h1 className='cool-title'>{lang === 'en' ? 'CONTACT' : 'Επικοινωνια '}</h1>
        <div className='contact-wrapper'>
        <form className='contact-form' onSubmit={(e) => {postData(e)}}>


          <div className='inline'>
            <div className='input'>
              <label htmlFor='first-name'>{lang === 'en' ? 'First Name:' : 'Όνομα:'}</label>
              <input type='text' name='first-name' id='first-name'  placeholder='e.g John' value={firstName} onChange={(e) => {setFirstName(e.target.value);}} />
            </div>
            <div className='input'>
              <label htmlFor='last-name'>{lang === 'en' ? 'Last Name:' : 'Επίθετο:'}</label>
              <input type='text' name='last-name' id='last-name'  placeholder='e.g Doe' value={lastName} onChange={(e) => {setLastName(e.target.value);}} />
            </div>
          </div>

          <div className='input'>
            <label htmlFor='email'>Email:</label>
            <input type='email' name='email' id='email' placeholder='e.g email@example.com' value={email} onChange={(e) => {setEmail(e.target.value);}} />
            <small className='contact-note'>{ lang === 'en' ? "Note: If you don't provide us with your email address, we have no way of getting back to you!" : 
            'Σημείωση: Εάν δεν δώσετε το email σας, δεν μπορούμε να επικοινωνήσουμε μαζί σας!'}</small>
          </div>

          <div className='input'>
            <label htmlFor='subject'>{lang === 'en' ? 'Subject:' : 'Θέμα:'}<span className='required-input'>*</span></label>
            <input type='text' name='subject' id='subject' required  placeholder='e.g Feedback' value={subject} onChange={(e) => {setSubject(e.target.value);}}/>
          </div>

          <div className='input'>
            <label htmlFor='body'>{lang === 'en' ? 'Message:' : 'Μήνυμα:'}<span className='required-input'>*</span></label>
            <textarea type='text' name='body' id='body' required placeholder='Message...' cols="30" rows="10" value={message} onChange={(e) => {setMessage(e.target.value);}}/>
          </div>

          <input hidden aria-label="Don't check this checkbox if you are not a robot." aria-checked={honeyPot} type='checkbox' checked={honeyPot} onChange={handleHoneypotChange} ></input>

          {success && <p className='success-message message'>{success}</p>}
          {err && <p className='error-message message'>{err}</p>}

          {/* <button type='submit' name='submit'>{lang === 'en' ? 'Submit' : 'Υποβολή'}  <i className="fa-solid fa-paper-plane"></i></button> */}
          <button type='submit' name='submit'>{lang === 'en' ? 'Submit' : 'Υποβολή'}  <SendIcon/></button>

        </form>

      <div className='second-column'>
        
        <SocialMedia lang={lang}/>

      </div>

      </div>

    </div>
    </>
  )
}
