import React from 'react'
import './Pagination.css'


function Pagination({page, totalPages, setPage}) {
  if(totalPages == 0) return null;
  var array = [];
  if (totalPages <= 8) array = Array.from(Array(totalPages).keys(), item => item + 1);;
  if (page  <=6 && totalPages > 8) array = [1,2,3,4,5,6,'...',totalPages];
  if (page > 6 && page == (totalPages - 2) && totalPages > 8) array = [1,'...',parseInt(page)-3,parseInt(page)-2,parseInt(page)-1,parseInt(page),parseInt(page)+1,totalPages];
  if (page > 6 && page == (totalPages - 1) && totalPages > 8) array = [1,'...',parseInt(page)-4,parseInt(page)-3,parseInt(page)-2,parseInt(page)-1,parseInt(page),totalPages];
  if (page > 6 && page == totalPages && totalPages > 8) array = [1,'...',parseInt(page)-5,parseInt(page)-4,parseInt(page)-3,parseInt(page)-2,parseInt(page)-1,totalPages];
  if (page > 6 && page < (totalPages - 2) && totalPages > 8) array = [1,'...',parseInt(page)-2,parseInt(page)-1,parseInt(page),parseInt(page)+1,'...',totalPages]

  return (
        <ul className="pagination modal-1">
            <li><a onClick={() => page > 1 ? setPage(parseInt(page)-1) : null} alt='previous page' className="prev">&laquo;</a></li>
            {/* <li><a href="#" class="active">1</a></li>
            <li> <a href="#">2</a></li>
            <li> <a href="#">3</a></li>
            <li> <a href="#">4</a></li>
            <li> <a href="#">5</a></li>
            <li> <a href="#">6</a></li>
            <li> <a href="#">7</a></li>
            <li> <a href="#">8</a></li>
  <li> <a href="#">9</a></li> */}



            {array.map((i, index) => {
              // return  <li><a class={parseInt(page)==(parseInt(i)) ? 'active' : ''} href={typeof(i) !== 'string' ? `?page=${parseInt(i)}` : '#'}>{i}</a></li>

              return  <li key={index}><a className={parseInt(page)==(parseInt(i)) ? 'active' : ''} alt={`page-${i}`} onClick={() => {typeof(i) !== 'string' && setPage(parseInt(i))}}>{i}</a></li>
            })}
            {/* {Array.from({ length: parseInt(totalPages) }, (_, i) => 

            <li><a class={page==(i+1) ? 'active' : ''} href={`?page=${i+1}`}>{i+1}</a></li>
            
            )} */}

            <li><a onClick={() => page < parseInt(totalPages) ? setPage(parseInt(page)+1) : null} alt='next page' className="next">&raquo;</a></li>
        </ul>
  )
}

export default Pagination