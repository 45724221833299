import React, { useState, useEffect } from 'react'
import './Event.css'
import axiosInstance from '../api/api'
import Preloader from './Preloader'
import { Link, useParams } from 'react-router-dom'
import EventMetas from '../metas/EventMetas'
import { useLocation } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
function Event({lang}) {

    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    const {eventSlug} = useParams();

    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState(null);
    const [err, setErr] = useState(false);
    
    async function fetchEvent() {
      
      const response = await axiosInstance.get(`/event/${eventSlug}?lang=${lang}`);
      setLoading(false);

      if (response?.data?.err && !response?.data?.found){
        setErr(true);
        return;
    }
      setEvent(response?.data?.data);
    }
    
  
    useEffect( () => {
        fetchEvent();
    }, [lang])
    
    if (loading) return <Preloader />
    if (err) return <div className='page-container'><h1>Not Found!</h1></div> // go to 404 page later...

  return (
    <>
    <EventMetas 
    title={event.title}
    description={event.excerpt}
    imageURI={event.thumbnail}
    />
    <div className='page-container'>
        <div className='event-wrapper'>
            <article>
                <div className="event-image">
                    <img src={event.thumbnail} alt='' />
                </div>
                <div className='event-details'>
                    <h1 class='event-title'>{event.title}</h1>
                    <div className='event-description'>
                        <div className='event-datetime'>
                        {/* <div className='event-time'><i class="fa-solid fa-calendar"></i><time>{new Date(event.event_time).toLocaleDateString(lang === 'gr'?'el-GR':'en', { year: 'numeric', month: 'long', day: 'numeric' })}</time></div> */}
                        <div className='event-time'><CalendarMonthIcon/><time>{new Date(event.event_time).toLocaleDateString(lang === 'gr'?'el-GR':'en', { year: 'numeric', month: 'long', day: 'numeric' })}</time></div>

                            {/* <div className='event-place'><i class="fa-solid fa-map-location-dot"></i>{event?.place}</div> */}
                            <div className='event-place'><LocationOnIcon/>{event?.place}</div>

                            {/* <div className='event-societies'><i className="fa-solid fa-people-group"></i> */}
                            <div className='event-societies'><PeopleAltIcon/>

                            
                            <ul>
                                {event.societies.map((society) => {return <>
                                    <li className={`${society.slug}-chapter`}><Link to={`/${lang}/chapter/${society.slug}/`}>{society.short_title}</Link></li><style>
              
              {`.${society.slug}-chapter a:hover {
                color: #${society.page_color}!important;

            }`}</style></>})}
                            </ul>
                            </div>
                        </div>
                        
                        <div className='event-body' dangerouslySetInnerHTML={{__html: event.body}}>

                        </div>
                    </div>
                </div>
            </article>
        </div>
    </div>
    </>
  )
}

export default Event