import React, {useState, useEffect} from 'react'
import Pagination from '../components/Pagination';
import Dropdown from '../components/Dropdown';
import SearchBar from '../components/SearchBar';
import NewsCard from '../components/NewsCard';
import axiosInstance from '../api/api'
import Preloader from './Preloader'
import NotFoundImage from '../assets/images/404.png'
import { Link } from 'react-router-dom';
import LandingMetas from '../metas/LandingMetas';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function News({lang}) {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState(1)



  async function fetchNews() {
   const response = await axiosInstance.get(`/news/?lang=${lang}&o=${order}&search=${searchTerm}&page=${page}`)
   setNews(response?.data?.data);
   setTotalPages(response?.data?.total_pages)
   setLoading(false);
  }

  useEffect( () => {
    fetchNews();
  // fetchEvents();
  }, [page, searchTerm, order, lang])


  const DROPDOWN_MENU = [
    {
      'value': 1,
      'en': 'Time Descending',
      'gr': 'Φθίνουσα',
    },
    {
      'value':2,
      'en': 'Time Ascending',
      'gr': 'Αύξουσα',
    }
  ]


  if(loading) return <Preloader />
  return (
    <>
    <LandingMetas lang={lang} />
    <Helmet><title>{lang === 'en' ? 'News' : 'Νέα'} - IEEE NTUA Student Branch</title></Helmet>
    <Helmet>
      <meta name="twitter:title" 
      content={`${lang === 'en' ? 'News' : 'Νέα'} - IEEE NTUA Student Branch`}
      data-rh="true"/>
    </Helmet>

    <div className='page-container'>
        <h1 className='cool-title'>{lang === 'en' ? 'News' : 'ΝΕΑ'}</h1>

    <div className='events-filters'>
      <Dropdown lang={lang} setOrder={setOrder} order={order} menu={DROPDOWN_MENU}/>
      <SearchBar setSearchTerm={setSearchTerm} lang={lang}/>
    </div>
    {searchTerm.length >= 1 && (lang === 'en' ? ('Searching for: '  + searchTerm) : ('Αναζήτηση για: ' + searchTerm)) }


    <Pagination page={page} setPage={setPage} totalPages={totalPages} />

    <div className='events-container'>

      {news.map((news_card, index) => {
        return <NewsCard  key={index} lang={lang} details={news_card} />
      })}


    </div>

    {(!news || news.length === 0) && <div className='events-not-found'>
            <img src={NotFoundImage} alt='Not Found' width={200} />
            <h1>No news found</h1>

            <Link to={`/${lang}`}>Go Home</Link>
        </div> }
    
    <Pagination page={page} setPage={setPage} totalPages={totalPages} />

    </div>
    </>
  )
}

export default News