import React from 'react'
import './PersonCard.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
function PersonCard({profileURL, fullName, role, facebook, linkedin, instagram, email}) {
  return (
      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
        <div className="our-team">
          <div className="picture">
            <img className="img-fluid" src={profileURL} loading='lazy'/>
          </div>
          <div className="team-content">
            <h3 className="name">{fullName}</h3>
            <h4 className="title">{role}</h4>
          </div>
          <ul className="social">
            {/* {facebook   &&   <li><a href={facebook} target="_blank" className="fa fa-facebook" aria-hidden="true"></a></li>} */}
            {facebook   &&   <li><a href={facebook} target="_blank" aria-hidden="true"><FacebookIcon/></a></li>}

            {/* {instagram  &&   <li><a href={instagram} target="_blank" className="fa-brands fa-instagram" aria-hidden="true"></a></li>} */}
            {instagram  &&   <li><a href={instagram} target="_blank" aria-hidden="true"><InstagramIcon/></a></li>}
            
            {/* {linkedin   &&   <li><a href={linkedin} target="_blank" className="fa fa-linkedin" aria-hidden="true"></a></li>} */}
            {linkedin   &&   <li><a href={linkedin} target="_blank" aria-hidden="true"><LinkedInIcon/></a></li>}

            {/* {email      &&   <li><a href={"mailto:"+email} target="_blank" className="fa-solid fa-envelope" aria-hidden="true"></a></li>} */}

            {email      &&   <li><a href={"mailto:"+email} target="_blank" aria-hidden="true"><EmailIcon/></a></li>}
          </ul>
        </div>
      </div>
  )
}

export default PersonCard