import React, { useEffect, useState } from 'react'
import './NewsArticle.css'
import axiosInstance from '../api/api'
import Preloader from './Preloader'
import { useParams } from 'react-router-dom'
import NewsArticleMetas from '../metas/NewsArticleMetas'
import { useLocation } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


function NewsArticle({lang}) {
    
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);


    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const {newsSlug} = useParams();


    async function getNewsArticle() {
        const response = await axiosInstance.get(`/news/article/${newsSlug}/?lang=${lang}`)    
        setData(response?.data?.data);
        setLoading(false);
    }
    useEffect( () => {
        getNewsArticle();
    }, [lang])
    if (loading) return <Preloader />

  return (
    <>
    <NewsArticleMetas
        title={data?.title}
        imageURI={data?.thumbnail}
        description={data?.excerpt}
    />

    <div className='page-container'>
        <div className='news-article-container'>
            <h2 className='news-title'>{data?.title}</h2>
            <img src={data?.thumbnail} 
            alt={data?.title}  className='news-article-image' />
            <div className='news-article-details'>
                                {/* <div className='news-time'><i className="fa-solid fa-calendar"></i><time>{new Date(data?.created_at).toLocaleDateString(lang === 'gr'?'el-GR':'en', { year: 'numeric', month: 'long', day: 'numeric' })}</time></div> */}
                                <div className='news-time'><CalendarMonthIcon/><time>{new Date(data?.created_at).toLocaleDateString(lang === 'gr'?'el-GR':'en', { year: 'numeric', month: 'long', day: 'numeric' })}</time></div>

                {/* <div className='news-time'><i class="fa-solid fa-calendar"></i><time>16 April 2023</time></div> */}
                <div className='news-article-body' dangerouslySetInnerHTML={{__html: data?.body}}>
                </div>

            </div>
        </div>
    </div>
    </>
    
  )
}

export default NewsArticle