import React, { useEffect, useState } from 'react'
import './Awards.css'
import axiosInstance from '../api/api';
import Preloader from './Preloader'
import LandingMetas from '../metas/LandingMetas';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
function Awards({lang}) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [awards, setAwards] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchAwards() {
    const response = await axiosInstance.get('/awards/');
    setAwards(response?.data?.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchAwards();
  },[])


  if (loading) return <Preloader />
  return (
    <>
    <LandingMetas lang={lang} />
    <Helmet><title>{lang === 'en' ? 'Awards' : 'Βραβέια'} - IEEE NTUA Student Branch</title></Helmet>
    <Helmet>
      <meta name="twitter:title" 
      content={`${lang === 'en' ? 'Awards' : 'Βραβέια'} - IEEE NTUA Student Branch`}
      data-rh="true"/>
    </Helmet>
    <Helmet>
      <meta property="og:title" 
      content={`${lang === 'en' ? 'Awards' : 'Βραβέια'} - IEEE NTUA Student Branch`} 
      data-rh="true" />
    </Helmet>

    <div className='page-container'>
        <h1 className='cool-title'>{lang === 'en' ? 'Awards' : 'Βραβεια'}</h1>

      <div className='awards-page-wrapper'>

      

      {/* {Object.keys(awards).map((key) => {
        return (
          <div class="awards-container">
          <div class="wrapper">
          <h1 className='title-with-hr title-orange'>{key}</h1>
          <ul class="sessions">
            {awards[key].map((award) => {
              return (
                <li>
                  <div class="time">{award.year}</div>
                  <p>{award.title}</p>
                </li>
              )
            })}
          </ul>
          </div>
        </div> 
        )
      })} */}
       <div className="awards-container">
       <div className="wrapper">
        <h1 className='title-with-hr title-orange'>IEEE NTUA SB</h1>
        <ul className='sessions'>
        {awards['IEEE NTUA SB'].map((award, index) => {
                  return (
                    <li key={`${'IEEE NTUA SB'}-${index}`}>
                      <div className="time">{award.year}</div>
                      <p>{award.title}</p>
                    </li>
                  )
                })}
        </ul>
          {Object.keys(awards).map((key) => {
            return (
               key !== "IEEE NTUA SB" && <>
              <h1 className='title-with-hr title-orange'>{key}</h1>
              <ul className="sessions">
                {awards[key].map((award, index) => {
                  return (
                    <li key={`${key}-${index}`}>
                      <div className="time">{award.year}</div>
                      <p>{award.title}</p>
                    </li>
                  )
                })}
              </ul>
              </>
            )
          })}
     </div>

    </div> 



        {/* <div class="awards-container">
          <div class="wrapper">
          <h1 className='title-with-hr title-orange'>IEEE NTUA SB</h1>
          <ul class="sessions">
            <li>
              <div class="time">2021-2022</div>
              <p>Regional Exemplary Student Branch Award Winner</p>
            </li>
            <li>
              <div class="time">2016-2017</div>
              <p>Regional Exemplary Student Branch Award Winner</p>
            </li>
            <li>
              <div class="time">2013</div>
              <p>IEEE Day Photo Contest 2013. The Best Photo Including “Landmark”</p>
            </li>
            <li>
              <div class="time">2013</div>
              <p>The Darrel Chong Student Activity Award</p>
            </li>
          </ul>
          </div>
        </div> 

        <div class="awards-container">
          <div class="wrapper">
          <h1 className='title-with-hr title-orange'>IEEE NTUA SB</h1>
          <ul class="sessions">
            <li>
              <div class="time">2021-2022</div>
              <p>Regional Exemplary Student Branch Award Winner</p>
            </li>
            <li>
              <div class="time">2016-2017</div>
              <p>Regional Exemplary Student Branch Award Winner</p>
            </li>
            <li>
              <div class="time">2013</div>
              <p>IEEE Day Photo Contest 2013. The Best Photo Including “Landmark”</p>
            </li>
            <li>
              <div class="time">2013</div>
              <p>The Darrel Chong Student Activity Award</p>
            </li>
          </ul>
          </div>
        </div>  */}

        
      </div>




    </div>
    </>
  )
}

export default Awards