import React, {useEffect} from 'react'
import './About.css'
import IEEEMaster from '../assets/images/landing/ieee_master.png'
import NTUAIEEE from '../assets/images/ieee_logo_optimized.png'
import IEEEGreece from '../assets/images/landing/ieee_greece.png'
import Pyrforos from '../assets/images/pyrforos.svg';
import LandingMetas from '../metas/LandingMetas'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
function About({lang}) {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    <LandingMetas lang={lang} />
    <Helmet><title>About - IEEE NTUA Student Branch</title></Helmet>
    <Helmet>
      <meta name="twitter:title" 
      content="About - IEEE NTUA Student Branch" 
      data-rh="true"/>
    </Helmet>
    <Helmet>
      <meta property="og:title" 
      content="About - IEEE NTUA Student Branch"
      data-rh="true" />
    </Helmet>

    <div className='page-container'>
        <h1 className='cool-title'>{lang === 'en' ? 'About' : 'Σχετικα με εμας'}</h1>
        
        <article className='about-section'>
            <section className='about-container'>
              <h2 className='about-container-title title-with-hr'>Institute of Electrical and Electronics Engineers</h2>
              
                <img src={IEEEMaster} width={200}  alt='IEEE - Advancing Technology for Humanity.' />
                
                {lang === 'en' ?
                <>
              <p>IEEE is the largest technical professional association in the world, focused on <mark>advancing technology for the benefit of humanity</mark>. It has over 420,000 members in 160 countries.</p>
                <br/>
              <p>It was founded in 1963, with new societies being established some years later, such as the Computer Society, the Communications Society, and the Robotics and Automation Society. Originating from a merger of the American Institute of Electrical Engineers (AIEE) and the Institute of Radio Engineers (IRE), it continues to be a driving force in the electrical and computer engineering sector today.</p>
                <br/>
              <p>Widely accredited amongst members of the industry, academia, and government organizations, it today publishes a range of journals and standards, covering the latest research, as well as being involved in education, outreach, and professional development. Most notably, IEEE developed the 802.11 wireless standard (also known as Wi-Fi), the Ethernet standard, and many significant computer graphics, biomedical engineering, and power engineering standards universally used today.</p></>
                 :
                 <>
                 <p>Το IEEE είναι το Ινστιτούτο Ηλεκτρολόγων και Ηλεκτρονικών Μηχανικών, η μεγαλύτερη τεχνική επαγγελματική ένωση στον κόσμο, που επικεντρώνεται στην <mark>προώθηση της τεχνολογίας προς όφελος της ανθρωπότητας</mark>. Έχει πάνω από 420.000 μέλη σε 160 χώρες.</p>
                <br/>
              <p>Ιδρύθηκε το 1963, ενώ μερικά χρόνια αργότερα ιδρύθηκαν νέες κοινότητες, όπως η Computer Society, η Communications Society και η Robotics and Automation Society. Προερχόμενο από τη συγχώνευση του American Institute of Electrical Engineers (AIEE) και του Institute of Radio Engineers (IRE), συνεχίζει να αποτελεί σήμερα κινητήρια δύναμη στον τομέα της Ηλεκτρολογίας και της Μηχανικής Υπολογιστών.</p>
                <br/>
              <p>Ευρέως διαπιστευμένο μεταξύ των μελών του κλάδου, των ακαδημαϊκών και των κυβερνητικών οργανισμών, σήμερα εκδίδει μια σειρά από περιοδικά και πρότυπα, καλύπτοντας την τελευταία έρευνα, καθώς και συμμετέχει στην εκπαίδευση, την προβολή και την επαγγελματική ανάπτυξη. Για παράδειγμα, το ΙΕΕΕ ανέπτυξε το πρότυπο ασύρματης επικοινωνίας 802.11 (γνωστό και ως Wi-Fi), το πρότυπο ethernet και πολλά σημαντικά πρότυπα γραφικών υπολογιστών, βιοϊατρικής μηχανικής και μηχανικής ενέργειας που χρησιμοποιούνται παγκοσμίως σήμερα.</p>
              </>
                 }
            </section>



            <section className='about-container'>
              <h2 className='about-container-title title-with-hr'>IEEE Greece Section</h2>
              <img src={IEEEGreece} width={200}  alt='IEEE Greece Section.' />

                {lang === 'en' ?
                <p>IEEE Greece Section has over 2000 members, professionals and students alike. It is one of the largest sections in the region of Europe, Asia, and Africa, actively organizing conferences, seminars, and workshops on a frequent basis.</p>
                :
                <p>Το IEEE Greece Section έχει πάνω από 2000 μέλη, επαγγελματίες και φοιτητές. Είναι ένα από τα μεγαλύτερα τμήματα στην περιοχή της Ευρώπης, της Ασίας και της Αφρικής, διοργανώνοντας ενεργά συνέδρια, σεμινάρια και εργαστήρια σε συχνή βάση.</p>
                }
              

            
            </section>


            <section className='about-container'>
              <h2 className='about-container-title title-with-hr'>IEEE NTUA Student Branch</h2>
              <img src={NTUAIEEE} width={200}  alt='IEEE NTUA - Where knowledge meets passion!' />

                {lang === 'en' ?
                <>
                <p>IEEE NTUA student branch is a volunteer organization at the National Technical University of Athens led by students. The branch is currently made up of five Chapters:</p>
                  <ul>
                    <li>Computer Society</li>
                    <li>Robotics and Automation Society</li>
                    <li>Power and Energy Society</li>
                    <li>Engineering in Medicine and Biology Society</li>
                    <li>Communications Society</li>
                  </ul>
              <br/>
              <p>The branch has well over 150 active members, collaborating on different projects, competitions, talks, and workshops organized in each subgroup of the student branch. Within the branch, we aim to encourage students to pursue their interests outside of the classroom. We regularly organize events for the public, discussing all technical issues as well as professional development.</p>
              <br/>
              <p>Overall, our goal is to inspire other students and create an active community based on <mark>creativity</mark> and <mark>innovation</mark>.</p>
                </>
                 :
                 <>
                 <p>To IEEE NTUA Student Branch είναι ένας εθελοντικός φοιτητικός οργανισμός στο Εθνικό Μετσόβιο Πολυτεχνείο. Αποτελείται από πέντε Chapters:</p>
                 <ul>
                    <li>Computer Society</li>
                    <li>Robotics and Automation Society</li>
                    <li>Power and Energy Society</li>
                    <li>Engineering in Medicine and Biology Society</li>
                    <li>Communications Society</li>
                  </ul>
                  <br/>
                <p>Ο κλάδος έχει πολύ περισσότερα από 150 ενεργά μέλη, τα οποία συνεργάζονται σε διάφορα projects, διαγωνισμούς, ομιλίες και workshops που διοργανώνονται σε κάθε υποομάδα των τμημάτων του. Στο πλαίσιο του branch, στόχος μας είναι να ενθαρρύνουμε τους φοιτητές να ακολουθήσουν τα ενδιαφέροντά τους εκτός της αίθουσας διδασκαλίας. Διοργανώνουμε τακτικά εκδηλώσεις για το κοινό, συζητώντας ταυτόχρονα για όλα τα τεχνικά θέματα καθώς και για την επαγγελματική ανάπτυξη.</p>
                <br/>
                <p>Συνολικά, στόχος μας είναι να εμπνεύσουμε άλλους φοιτητές και να δημιουργήσουμε μια ενεργή κοινότητα που βασίζεται στη <mark>δημιουργικότητα</mark> και την <mark>καινοτομία</mark>.</p>
                 </>
                 }

              
            
            </section>

            <section className='about-container'>
              <h2 className='about-container-title title-with-hr'>National Technical University of Athens</h2>
              <img src={Pyrforos} width={200}  alt='National Technical University of Athens.' />

                {lang === 'en' ?
                <>
                <p>The National Technical University of Athens is one of the oldest and leading technical universities in Greece. Founded in 1836, it is a historical public university with two campuses: the Patission complex and the Zografou campus. The university consists of nine schools:</p>
                  <ul>
                    <li>Electrical and Computer Engineering</li>
                    <li>Mechanical Engineering</li>
                    <li>Chemical Engineering</li>
                    <li>Civil Engineering</li>
                    <li>Surveying Engineering</li>
                    <li>Naval Architecture and Marine Engineering</li>
                    <li>Metallurgical Engineering</li>
                    <li>Architecture</li>
                    <li>Applied Mathematics and Physics</li>
                  </ul>
                <br/>
                <p>The School of Electrical and Computer Engineering is the biggest, with over 2000 students. It is located on the Zografou campus, and the curriculum of studies consists of 12 tracks, including Computer Systems, Software Engineering, Robotics, Biomedicine, Power and Energy, and Electronics.</p>
                </>
                 :
                 <>
                 <p>Το Εθνικό Μετσόβιο Πολυτεχνείο είναι ένα από τα παλαιότερα και το κορυφαίο τεχνικό πανεπιστήμιο στην Ελλάδα. Ιδρύθηκε το 1836 και είναι ένα ιστορικό δημόσιο πανεπιστήμιο που διαθέτει δύο πανεπιστημιουπόλεις: το συγκρότημα Πατησίων και την πανεπιστημιούπολη Ζωγράφου. Το πανεπιστήμιο αποτελείται από 9 σχολές:</p>
                 <ul>
                    <li>Ηλεκτρολόγων Μηχανικών και Μηχανικών Υπολογιστών</li>
                    <li>Μηχανολόγων Μηχανικών</li>
                    <li>Χημικών Μηχανικών</li>
                    <li>Τοπογράφων Μηχανικών</li>
                    <li>Ναυπηγών Μηχανικών</li>
                    <li>Μεταλλειολόγων Μηχανικών</li>
                    <li>Αρχιτεκτόνων Μηχανικών</li>
                    <li>Εφαρμοσμένων Μαθηματικών και Φυσικής</li>
                  </ul>
                 <br/>
                 <p>Η Σχολή Ηλεκτρολόγων Μηχανικών και Μηχανικών Υπολογιστών είναι η μεγαλύτερη, με περισσότερους από 2000 φοιτητές. Στεγάζεται στην πανεπιστημιούπολη Ζωγράφου και το πρόγραμμα σπουδών αποτελείται από 12 ροές, οι οποίες περιλαμβάνουν μεταξύ άλλων Συστήματα Υπολογιστών, Πληροφορική, Ρομποτική, Βιοϊατρική, Ηλεκτρική και Ενέργεια και Ηλεκτρονική.</p>
                </>
                 }

              

            
            </section>



        </article>

    </div>
    </>
  )
}

export default About