import React from 'react'
import './SocialMedia.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';


function SocialMedia({lang}) {
  return (
    <>
    <h2 className='title-with-hr'>{lang === 'en' ? 'Find us on social media!' : 'Βρείτε μας στα social media!'}</h2>
    <div className="social-buttons">
    <a href="https://www.facebook.com/IeeeNtuaSB" target="_blank" className="social-button social-button--facebook" aria-label="Facebook">
      {/* <i className="fab fa-facebook-f"></i> */}
    <FacebookIcon/>
    </a>
    <a href="https://www.instagram.com/ieee_ntua_sb/" target="_blank" className="social-button social-button--instagram" aria-label="LinkedIn">
      {/* <i className="fa-brands fa-instagram"></i> */}
      <InstagramIcon/>
    </a>
    <a href="https://www.linkedin.com/company/ieee-ntua-student-branch/" target="_blank" className="social-button social-button--linkedin" aria-label="LinkedIn">
      {/* <i className="fab fa-linkedin-in"></i> */}
      <LinkedInIcon/>
    </a>
    <a href="mailto:ieee.ntua.sb@gmail.com" target="_blank" className="social-button social-button--email" aria-label="Email">
      {/* <i className="fa-solid fa-envelope"></i> */}
      <EmailIcon/>
    </a>
    <a href="https://goo.gl/maps/M2zEcpkcfHD4aMif8" target="_blank" className="social-button social-button--location" aria-label="Location">
      {/* <i className="fa-solid fa-location-dot"></i> */}
      <LocationOnIcon/>
    </a>
  </div>
  </>
  )
}

export default SocialMedia