import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './EventCard.css'
import PeopleIcon from '@mui/icons-material/People';
import MapIcon from '@mui/icons-material/Map';
export default function EventCard({lang, title, eventTime, expired,societies, slug, imageUrl, excerpt, place}) {


  // const [reminderToggle, setReminderToggle] = useState(false);
  // const [emailReminder, setEmailReminder] = useState('');
  // const [honeypot, setHoneypot] = useState(false);


  // async function setReminder() {
  //   const response = await axiosInstance.post('/email-reminder/',
  //   {
  //     event_slug: slug,
  //     lang: lang,
  //     email: emailReminder,
  //   },
  //   {
  //     headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }})
  //   setEmailReminder('');
  //   setReminderToggle(false);
  // }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   if (honeypot) return;
  //   setReminder();
  //   setEmailReminder('');
  // }

  return (

    <div className='event-card'>
    <div className='event-card-image'>
      <img src={imageUrl} alt={title + "."} loading="lazy" />

  {/* {reminderToggle && 
    <form className='email-reminder' onSubmit={(e) => {handleSubmit(e)}}>
    <h4>{lang === 'en' ? 'Set Reminder' : 'Θέσε Reminder'}</h4>
    <div className='inline'>
      <label for='email' hidden>{lang === 'en' ? 'E-mail' : 'E-mail'}</label>
      <input type='email' name='email' placeholder='E-mail:' value={emailReminder} onChange={(e) => setEmailReminder(e.target.value)} />
      <input type='checkbox' name='honeypot' value={honeypot} onChange={(e) => setHoneypot(e.target.value)} hidden />
      <button type='submit'>{lang === 'en' ? 'Remind Me!' : 'Remind Me!'}</button>
    </div>
    </form>
  } */}
     


      <div className='event-card-time'><time>{new Date(eventTime).toLocaleDateString(lang === 'gr'?'el-GR':'en', { year: 'numeric', month: 'long', day: 'numeric' })}</time></div>
      
      {/* {!expired && <button type='button' className='reminder-button' onClick={ () => { setReminderToggle(!reminderToggle) } } >
        <i className="fa-regular fa-bell"></i>      
        </button>} */}
    
    </div>

    <div className='event-card-details'>
      
      <h3 className='event-card-title'>{title}</h3>
      <div className='seperator'></div>
      {expired &&  <div className='event-card-expired'>{lang === 'en' ? 'Expired' : 'Έληξε'}</div>}
      <div className='event-chapters'>
        {/* <i className="fa-solid fa-people-group"></i> */}
        <PeopleIcon/>
        <ul className='event-chapters-list'>
          {societies.map((society, index) => { return <>
            <li key={index} className={`${society.slug}-chapter`}><Link to={`/${lang}/chapter/${society.slug}/`}>{society.short_title}</Link></li>
            <style>  
              {`.${society.slug}-chapter a:hover {
                color: #${society.page_color}!important;
            }`}
            </style>
          </>
          })}
        </ul>
      </div>
     <div className='event-card-place'>
     {/* <i className="fa-solid fa-map-location-dot"></i> */}
     <MapIcon/>
            {place}
    </div>
    
    {/* <div className='event-card-time'>
        <img src={Calendar} alt='time' width='15'/>
            <time>31st March 2023</time></div> */}
      <p className='event-card-description'>{excerpt}</p>
    
    <div className='event-card-buttons'>
      <Link to={`/${lang}/event/${slug}/`} className='event-page-button border-gradient'>{lang === 'en' ? 'View More' : 'Δείτε Περισσότερα'}</Link>
      {/* <button className='event-page-button border-gradient'>Remind Me</button> */}

    </div>

      </div>
     
  </div>
  )
}
