import React from 'react'
import './NewsCard.css'
import { Link } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function NewsCard({lang, details}) {

  return (
    <div className='news-card'>
        <img  className='new-card-img' src={details.thumbnail} alt={details?.title + "."} loading="lazy" />
          <div className='new-card-details'>
              <h3 className='news-card-title'>{details.title}</h3>
              <div className='news-card-tags'>
                  <div className='news-card-time'>
                    {/* <i className="fa-regular fa-calendar"></i>                     */}
                    <CalendarMonthIcon/>
                    <time><small>{new Date(details.created_at).toLocaleDateString(lang === 'gr'?'el-GR':'en', { year: 'numeric', month: 'long', day: 'numeric' })}</small></time>
                  </div>
                  {/* <div className='news-card-read'>
                  <i class="fa-regular fa-clock"></i>                   
                  <small>6 min read</small>
                  </div> */}
              </div>
              <div className='news-card-description'>
                {details.excerpt}
              </div>
              <Link to={`article/${details.slug}`} className='news-card-button' 
              aria-label={lang === 'en' ? 'Click here to read more.' : 'Πατήστε εδω για να διαβάσετε περισσότερα'}>
                {lang === 'en' ? 'Read More' : 'Διαβάστε Περισσότερα'}</Link>

          </div>
       
    </div>
  )
}

export default NewsCard